@charset "UTF-8";
/* Template Name: Techwind - Tailwind CSS Multipurpose Landing & Admin Dashboard Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 2.0.0
   Created: May 2022
   File: Main Css File
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* // _general.scss */
/*********************************/
/*             General            */
/*===============================*/
p {
  @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply leading-normal;
}

::selection {
  @apply bg-indigo-600/90 text-white;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  @apply fixed start-0 end-0 top-0 z-999 bg-transparent border-0 duration-500;
}
#topnav .logo {
  @apply ltr:float-left rtl:float-right text-black dark:text-white !important;
}
#topnav .logo .l-dark {
  @apply hidden;
}
#topnav .logo .l-light {
  @apply inline-block;
}
#topnav .logo:focus {
  @apply outline-none;
}
#topnav .has-submenu {
  @apply relative;
}
#topnav .has-submenu.active a {
  @apply text-white dark:text-slate-900;
}
#topnav .has-submenu.active .submenu li.active > a {
  @apply text-indigo-600 dark:text-indigo-600 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  @apply border-indigo-600 dark:border-indigo-600;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0px 0.125rem 0.125rem 0px;
  @apply absolute end-[1.25rem] top-[15px] border-black dark:border-white rounded-[0.5px] inline-block p-[2px] ltr:-rotate-[45deg] rtl:rotate-[135deg];
}
#topnav .has-submenu .submenu .has-submenu:hover {
  /* .submenu-arrow {
    @apply border-indigo-600 dark:border-indigo-600;
  } */
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-indigo-600 dark:border-indigo-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  @apply border-indigo-600 dark:border-indigo-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-indigo-600 dark:border-indigo-600;
}
#topnav .navigation-menu > li .submenu li {
  @apply relative ms-0;
}
#topnav .navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
}
#topnav .navbar-toggle .lines {
  @apply w-[25px] block relative h-[18px] mt-[30px] me-0 mb-[26px] ms-[10px];
}
#topnav .navbar-toggle span {
  transition: transform 0.5s ease;
  @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
}
#topnav .navbar-toggle span:last-child {
  @apply mb-0;
}
#topnav .navbar-toggle.open span {
  @apply absolute;
}
#topnav .navbar-toggle.open span:first-child {
  @apply top-[6px] rotate-[45deg];
}
#topnav .navbar-toggle.open span:nth-child(2) {
  @apply invisible;
}
#topnav .navbar-toggle.open span:last-child {
  @apply w-full top-[6px] -rotate-[45deg];
}
#topnav .navbar-toggle.open span:hover {
  @apply bg-indigo-600;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  @apply bg-transparent;
}
#topnav .buy-button {
  @apply ltr:float-right rtl:float-left leading-[74px];
}
#topnav .buy-button > li {
  @apply leading-[initial];
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  @apply hidden;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  @apply inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  @apply relative;
}
#topnav .buy-button .search-bar .searchform {
  @apply after:absolute after:end-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  @apply shadow-none py-[10px] pe-[42px] ps-3 h-11 text-sm block outline-none  !important;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  @apply hidden;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle,
#topnav .buy-menu-btn .dropdown .dropdown-toggle {
  @apply after:hidden;
}
#topnav .navigation-menu {
  @apply list-none m-0 p-0;
}
#topnav .navigation-menu > li {
  @apply ltr:float-left rtl:float-right block relative my-0 mx-[10px];
}
#topnav .navigation-menu > li > a {
  @apply block text-black dark:text-white text-[13px] bg-transparent font-bold tracking-[1px] leading-6 uppercase px-[14px] !important;
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  @apply text-indigo-600;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  @apply text-indigo-600 dark:text-indigo-600 !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-bold text-slate-400 !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  @apply border-black dark:border-white rounded-[0.5px] inline-block p-[2px] rotate-[45deg] absolute duration-500 end-0 top-8;
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  @apply rotate-[225deg];
}
#topnav .menu-extras {
  @apply ltr:float-right rtl:float-left;
}
#topnav.scroll {
  @apply bg-white dark:bg-slate-900 border-none shadow;
}
#topnav.scroll .navigation-menu > li > a {
  @apply text-black dark:text-white;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  @apply text-indigo-600;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  @apply border-indigo-600 dark:border-indigo-600;
}
#topnav.defaultscroll.dark-menubar .logo {
  @apply leading-[70px];
}
#topnav.defaultscroll.scroll .logo {
  @apply leading-[62px];
}
#topnav.nav-sticky {
  @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  @apply text-black dark:text-white !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-indigo-600 dark:text-indigo-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  @apply border-indigo-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-indigo-600 dark:text-indigo-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.nav-sticky.tagline-height {
  @apply top-0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  @apply hidden;
}
#topnav.nav-sticky .logo .l-dark {
  @apply inline-block;
}
#topnav.nav-sticky .logo .l-light {
  @apply hidden;
}

.logo {
  @apply font-bold text-[24px] me-[15px] pe-[15px] pt-0 pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[1120px] !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[936px] !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    @apply flex flex-wrap justify-center;
  }
  #topnav .navigation-menu.justify-end li:last-child {
    @apply me-0;
  }
  #topnav .navigation-menu.justify-end li:last-child .sub-menu-item {
    @apply pe-0 !important;
  }
  #topnav .navigation-menu.justify-start > li:first-child {
    @apply ms-0;
  }
  #topnav .navigation-menu.justify-start > li:first-child .sub-menu-item {
    @apply ps-0 !important;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    @apply top-[34px] !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    @apply top-8;
  }
  #topnav .navigation-menu > li .submenu {
    transition: all 0.2s ease;
    @apply absolute top-full start-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800;
  }
  #topnav .navigation-menu > li .submenu li {
    @apply relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[11px] uppercase tracking-[0.04em] font-bold text-black dark:text-white leading-[17px];
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    @apply text-indigo-600 dark:text-indigo-600 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    @apply list-none ps-0 m-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply whitespace-nowrap start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 fixed top-auto flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    @apply overflow-hidden align-top w-1/5;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    @apply start-full top-0 ms-[10px] -mt-px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    @apply start-[101%] top-0 ms-[10px] -mt-px;
  }
  #topnav .navigation-menu > li > a {
    @apply py-[25px] min-h-[62px];
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    @apply border-indigo-600 dark:border-indigo-600;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    @apply text-indigo-600 dark:text-indigo-600 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    @apply start-auto end-0 before:start-auto before:end-[10px];
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    @apply start-auto end-full ms-0 me-[10px];
  }
  #topnav .navigation-menu.nav-light > li > a {
    @apply text-white/50 !important;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    @apply border-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    @apply border-white/50;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    @apply border-white !important;
  }
  #topnav .buy-button {
    @apply ps-[15px] ms-[15px];
  }
  #topnav .navbar-toggle {
    @apply hidden;
  }
  #topnav #navigation {
    @apply block !important;
  }
  #topnav.scroll {
    @apply top-0;
  }
  #topnav.scroll .navigation-menu > li > a {
    @apply py-5;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    @apply py-[25px];
  }
}
@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 min-h-[74px];
  }
  #topnav .logo .l-dark {
    @apply inline-block !important;
  }
  #topnav .logo .l-light {
    @apply hidden !important;
  }
  #topnav .container {
    @apply w-auto;
  }
  #topnav #navigation {
    @apply max-h-[400px] shadow;
  }
  #topnav .navigation-menu {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li .submenu {
    @apply hidden list-none ps-5 m-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block relative py-[7px] px-[15px] uppercase text-[11px] tracking-[0.04em] font-bold text-black dark:text-white !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    @apply py-[7px] px-[15px];
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    @apply list-none ps-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
  }
  #topnav .navigation-menu > li .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    @apply hidden list-none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li > a {
    @apply text-black dark:text-white py-[10px] px-[20px] after:absolute after:end-[15px];
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    @apply text-indigo-600;
  }
  #topnav .menu-extras .menu-item {
    @apply border-gray-200 dark:border-gray-700;
  }
  #topnav .navbar-header {
    @apply ltr:float-left rtl:float-right;
  }
  #topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
    @apply inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
    @apply hidden;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    @apply rotate-[45deg] absolute end-5 top-3;
  }
  #topnav .has-submenu.active a {
    @apply text-indigo-600;
  }

  #navigation {
    @apply absolute top-[74px] start-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
  }
  #navigation.open {
    @apply block overflow-y-auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    @apply end-2 top-4;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 mt-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 ms-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    @apply visible opacity-100 ms-0;
  }

  .navbar-toggle {
    @apply block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    @apply block my-0 mx-[10px] py-[10px] px-5 !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}

@media screen and (max-width: 575px) {
  .tagline {
    @apply hidden;
  }

  .tagline-height {
    @apply top-0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] !important;
  }
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  @apply text-indigo-600;
}
.sidebar-nav li.active a {
  @apply text-indigo-600;
}
.sidebar-nav li.account-menu.active .navbar-link, .sidebar-nav li.account-menu:hover .navbar-link {
  @apply text-indigo-600 dark:text-white !important;
}

/*********************************/
/*             Helper            */
/*===============================*/
/* Cookies */
.cookie-popup-not-accepted {
  @apply block;
  animation: cookie-popup-in 0.5s ease forwards;
}

.cookie-popup-accepted {
  @apply hidden;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
/* Shapes */
.shape.marketing-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply fixed inset-0;
}
#preloader #status {
  @apply absolute start-0 end-0 top-1/2 -translate-y-1/2;
}
#preloader #status .spinner {
  @apply w-10 h-10 relative my-[100px] mx-auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  @apply w-full h-full rounded-full bg-indigo-600/60 absolute top-0 start-0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* Switcher */
.label .ball {
  transition: transform 0.2s linear;
  @apply translate-x-0;
}

.checkbox:checked + .label .ball {
  @apply translate-x-6;
}

.mover {
  animation: mover 1.5s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
/*********************************/
/*             Home            */
/*===============================*/
/* background-effect For Job Hero */
.background-effect .circles li {
  @apply absolute block -bottom-[150px] bg-indigo-600/30;
  animation: animate 25s linear infinite;
}
.background-effect .circles li.brand-img:nth-child(1), .background-effect .circles li.brand-img:nth-child(2), .background-effect .circles li.brand-img:nth-child(3), .background-effect .circles li.brand-img:nth-child(4), .background-effect .circles li.brand-img:nth-child(5), .background-effect .circles li.brand-img:nth-child(6), .background-effect .circles li.brand-img:nth-child(7), .background-effect .circles li.brand-img:nth-child(8), .background-effect .circles li.brand-img:nth-child(9), .background-effect .circles li.brand-img:nth-child(10) {
  @apply bg-transparent;
}
.background-effect .circles li:nth-child(1), .background-effect .circles li:nth-child(2), .background-effect .circles li:nth-child(3), .background-effect .circles li:nth-child(4), .background-effect .circles li:nth-child(5), .background-effect .circles li:nth-child(6), .background-effect .circles li:nth-child(7), .background-effect .circles li:nth-child(8), .background-effect .circles li:nth-child(9), .background-effect .circles li:nth-child(10) {
  @apply w-12 h-12;
}
.background-effect .circles li:nth-child(1) {
  @apply start-1/4;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(2) {
  @apply start-[10%];
  animation-delay: 2s;
  animation-duration: 12s;
}
.background-effect .circles li:nth-child(3) {
  @apply start-[70%];
  animation-delay: 4s;
}
.background-effect .circles li:nth-child(4) {
  @apply start-[40%];
  animation-delay: 0s;
  animation-duration: 18s;
}
.background-effect .circles li:nth-child(5) {
  @apply start-[65%];
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(6) {
  @apply start-3/4;
  animation-delay: 3s;
}
.background-effect .circles li:nth-child(7) {
  @apply start-[35%];
  animation-delay: 7s;
}
.background-effect .circles li:nth-child(8) {
  @apply start-1/2;
  animation-delay: 15s;
  animation-duration: 45s;
}
.background-effect .circles li:nth-child(9) {
  @apply start-[20%];
  animation-delay: 2s;
  animation-duration: 35s;
}
.background-effect .circles li:nth-child(10) {
  @apply start-[85%];
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 10px;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
/* Kenburn Effect for Business Demo */
.image-wrap {
  animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
  0% {
    transform-origin: bottom;
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
@keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}
.spa-css {
  @apply font-para-worksans !important;
}
.spa-css #topnav .navigation-menu > li > a {
  @apply text-[14px] font-medium px-[12px] !important;
}
.spa-css #topnav .navigation-menu > li .submenu li a {
  @apply text-[12px] font-medium !important;
}
.spa-css #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply font-medium !important;
}

/*********************************/
/*         Countdown             */
/*===============================*/
#token-sale .count-number .count-head {
  @apply relative block uppercase md:-mt-5 md:text-base text-sm leading-[1px] font-bold;
}

#countdown .count-down .count-head {
  @apply relative block -translate-y-[25px] uppercase text-sm tracking-[1px];
}

/* //maintenance */
#maintenance .indicator {
  @apply text-lg;
}

/*********************************/
/*           Portfolio           */
/*===============================*/
.container-filter li.active, .container-filter li:hover {
  @apply text-slate-900 dark:text-white !important;
}

.container-filter-box li.active, .container-filter-box li:hover {
  @apply text-indigo-600 border-indigo-600 !important;
}

.container-filter-border-bottom li.active, .container-filter-border-bottom li:hover {
  @apply text-slate-900 dark:text-white border-b-slate-900 dark:border-b-white !important;
}

.container-filter-white li.active, .container-filter-white li:hover {
  @apply text-white border-b-white !important;
}

.container-creative li {
  @apply after:content-[""] after:absolute after:h-0 after:w-0 after:bg-indigo-600/40 after:end-0 after:start-0 after:bottom-[5px] after:rounded-md after:duration-500 after:ease-in-out;
}
.container-creative li:hover, .container-creative li.active {
  @apply after:w-full after:end-auto after:start-0 after:h-[5px] after:top-[60%] text-black dark:text-white !important;
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
/* Tobii Lighbox */
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  @apply h-9 w-auto;
}

.tobii__counter {
  @apply text-base;
}

.tobii-zoom {
  @apply block;
}

.tobii-zoom__icon {
  @apply hidden;
}

#grid {
  @apply p-0;
}

@media (max-width: 640px) {
  [data-type] iframe, [data-type] video {
    width: 500px;
    height: 300px;
  }
}
@media (max-width: 425px) {
  [data-type] iframe, [data-type] video {
    width: 360px;
    height: 260px;
  }
}
@media (max-width: 375px) {
  [data-type] iframe, [data-type] video {
    width: 100%;
    height: auto;
  }
}
/*********************************/
/*             Contact           */
/*===============================*/
/* Validation */
.error {
  @apply my-2 mx-0 hidden text-red-600;
}

#ajaxsuccess {
  @apply text-base w-full hidden clear-both my-2 mx-0;
}

.error_message,
#success_page {
  @apply p-2.5 mb-5 text-center rounded-md;
}

.error_message {
  @apply bg-red-600/5 text-red-600;
}

.contact-loader {
  @apply hidden;
}

#success_page {
  @apply bg-emerald-600/5 text-emerald-600;
}

/*****************/
/*  Swiper Slider     */
/*****************/
.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  font-family: "Material Design Icons";
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}
.swiper-slider-hero .swiper-pagination-bullet {
  @apply text-gray-50 bg-transparent;
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  @apply text-white;
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  @apply bottom-[45px];
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply my-0 mx-[13px];
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.tns-nav {
  @apply text-center mt-3;
}
.tns-nav button {
  @apply rounded-[3px] bg-indigo-600/30 duration-500 border-0 m-1 p-[5px];
}
.tns-nav button.tns-nav-active {
  @apply bg-indigo-600 rotate-[45deg];
}

/* Tns control */
.tns-controls button[data-controls=prev],
.tns-controls button[data-controls=next] {
  @apply absolute top-2/4 -translate-y-2/4 text-base w-8 h-8 rounded-full bg-white dark:bg-slate-900 text-dark dark:text-white border-0 duration-500 z-10 shadow-md dark:shadow-gray-800;
}
.tns-controls button[data-controls=prev]:hover,
.tns-controls button[data-controls=next]:hover {
  @apply bg-indigo-600 text-white;
}
.tns-controls button[data-controls=prev] {
  @apply start-0;
}
.tns-controls button[data-controls=next] {
  @apply end-0;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-360px * 6));
  }
}
.slider:after {
  transform: rotateZ(360deg);
}
.slider .slide-track {
  animation: scroll 120s linear infinite;
  width: calc(360px * 20);
}

/* RTL */
html[dir=rtl] .tns-inner, html[dir=rtl] .tns-nav, html[dir=rtl] .slider, html[dir=rtl] .slider2, html[dir=rtl] .swiper-slider-hero {
  direction: ltr !important;
}
html[dir=rtl] .tns-visually-hidden {
  left: 0 !important;
  visibility: hidden;
}

/*********************************/
/*           Choices             */
/*===============================*/
.registration-form .submit-btn {
  @apply rounded-lg;
}
.registration-form .form-input,
.registration-form .choices[data-type*=select-one] .choices__inner {
  @apply rounded-lg;
}

@media (min-width: 992px) {
  .registration-form .choices[data-type*=select-one] .choices__inner {
    @apply rounded-none;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  @apply shadow-none border-0 rounded-none text-[15px] h-[60px] pt-[13px] pe-[6px] pb-[15px] ps-[45px];
}

.choices__inner {
  @apply bg-gray-50 dark:bg-slate-800;
}

.choices__list--dropdown,
.choices[data-type*=select-one] .choices__input {
  @apply bg-white dark:bg-slate-900 border-0 rounded-[5px] shadow dark:shadow-gray-800 z-999;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  @apply bg-gray-50 dark:bg-slate-800 text-black dark:text-white;
}

.choices__list,
.choices__list--dropdown .choices__item--selectable {
  @apply text-slate-400;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  @apply border-0 z-2;
}

.choices[data-type*=select-one] {
  @apply after:border-0 after:top-5 after:end-7 after:start-auto after:text-slate-900 dark:after:text-white;
}
.choices[data-type*=select-one]:after {
  content: "󰅀" !important;
  font-family: "Material Design Icons";
}

/*********************************/
/*         Datepicker           */
/*===============================*/
.qs-datepicker-container {
  @apply bg-white dark:bg-slate-900 border-inherit dark:border-gray-800;
}

.qs-datepicker-container .qs-controls {
  @apply bg-gray-50 dark:bg-slate-800;
}

.qs-datepicker-container .qs-controls .qs-month-year {
  @apply font-semibold text-[15px] dark:text-white;
}

.qs-datepicker-container .qs-controls .qs-month-year:hover {
  @apply border-b rounded-md outline-inherit border-inherit;
}

.qs-squares .qs-square {
  @apply text-[15px] h-[34px] w-[34px] text-slate-400;
}

.qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
.qs-squares .qs-square.qs-current {
  text-decoration: none;
  @apply bg-indigo-600 text-white rounded-[30px] font-semibold;
}

.qs-squares .qs-day {
  @apply font-normal text-slate-900 dark:text-white;
}